<template>
    <div class="h-full pt-10 bg-white flex flex-col">
        <MessageThreads
            v-if="!activeThread && !startChat"
            :threads="messageThreads"
            :openStartChat="openStartChat"
            :openThread="openThread"
        />
        <StartChat
            v-if="!activeThread && startChat"
            :closeStartChat="closeStartChat"
            :sendMessage="sendMessage"
        />
        <ThreadMessages
            v-if="activeThread"
            :thread="activeThread"
            :messages="activeThreadMessages"
            :sendMessage="sendMessage"
            :closeThread="closeThread"
            :markThreadRead="markThreadRead"
        />
        <div class="h-10 flex flex-row justify-center items-center border-t border-gray-300 mt-auto">
            <span
                @click="goHome"
                class="cursor-pointer text-gray-600 hover:text-gray-700 transition duration-150 ease-in-out">
                <homeSvg class="h-5 fill-current pointer-events-none" />
            </span>
        </div>
    </div>
</template>

<script>
import {
    mapState,
    mapGetters,
} from 'vuex';

export default {
    name: 'Messaging',

    props: {
        goHome: {
            type: Function,
            default: () => () => {},
        },
    },

    data() {
        return {
            startChat: false,
        };
    },

    computed: {
        ...mapState('simulator/messaging', {
            activeThread: 'activeThreadId',
        }),

        ...mapGetters('simulator/messaging', [
            'messageThreads',
            'activeThreadMessages',
        ]),
    },

    beforeDestroy() {
        this.closeThread();
    },

    methods: {
        sendMessage(m) {
            this.$store.dispatch('simulator/messaging/sendMessage', m);
        },

        openThread(threadId) {
            this.$store.commit('simulator/messaging/setActiveThreadId', threadId);
        },

        closeThread() {
            this.$store.commit('simulator/messaging/setActiveThreadId', null);
        },

        markThreadRead(threadId) {
            this.$store.commit('simulator/messaging/markThreadRead', threadId);
        },

        openStartChat() {
            this.startChat = true;
        },

        closeStartChat() {
            this.startChat = false;
        },
    },

    components: {
        homeSvg: () => import('@/assets/img/home.svg'),
        MessageThreads: () => import('./MessageThreads.vue'),
        ThreadMessages: () => import('./ThreadMessages.vue'),
        StartChat: () => import('./StartChat.vue'),
    },
};
</script>
